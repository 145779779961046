<template>
  <div>
    <v-menu v-model="filterDialog" width="500" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark text v-bind="attrs" v-on="on">
          <v-icon>mdi-filter-outline</v-icon>
          {{ $t("baseline.filterYears") }}
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="pt-1">
          <v-checkbox
            :label="$t('baseline.selectAll')"
            @click="toggleAllYears"
            v-model="activateAll"
            hide-details
          ></v-checkbox>
          <v-divider />
          <v-row no-gutters v-for="year in years" :key="year">
            <v-checkbox
              :value="year"
              :label="year"
              v-model="selectedYears"
              hide-details
            ></v-checkbox>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "YearFilter",
  props: {
    years: Array
  },
  data: function() {
    return {
      selectedYears: [],
      activateAll: true,
      filterDialog: false
    };
  },
  computed: {
    filteredDatetimeSelection() {
      let selection = this.datetimeSelection;
      if (this.startDate) {
        selection = selection.filter(item => item.datetime >= this.startDate);
      }
      if (this.endDate) {
        selection = selection.filter(item => item.datetime <= this.endDate);
      }
      selection = selection.filter(item =>
        this.selectedSensors.includes(item.sensor)
      );
      return selection.filter(item =>
        this.selectedMonths.includes(item.datetime.slice(5, 7))
      );
    }
  },
  methods: {
    toggleAllYears() {
      if (this.activateAll) {
        this.selectedYears = this.years;
      } else {
        this.selectedYears = [];
      }
    }
  },
  watch: {
    selectedYears: {
      handler(value) {
        const years = JSON.parse(JSON.stringify(value));
        years.sort(function(a, b) {
          return a - b;
        });
        this.$emit("filteredYears", years);
      }
    }
  },
  created() {
    this.selectedYears = this.years;
  }
};
</script>

<style scoped></style>
