// import axios from "axios";

import axios from "axios";
import { mapGetters, mapState } from "vuex";

let baselineMixin = {
  data: () => ({
    colorArray: [
      "#FF6633",
      "#FF33FF",
      "#00B3E6",
      "#E6B333",
      "#3366E6",
      "#99FF99",
      "#80B300",
      "#6680B3",
      "#66991A",
      "#FF99E6",
      "#FF1A66",
      "#33FFCC",
      "#B48C3B",
      "#66994D",
      "#B366CC",
      "#B33300",
      "#CC80CC",
      "#66664D",
      "#991AFF",
      "#E666FF",
      "#4DB3FF",
      "#1AB399",
      "#E666B3",
      "#33991A",
      "#CC9999",
      "#B3B31A",
      "#00E680",
      "#FF3380",
      "#CCCC00",
      "#66E64D",
      "#4D80CC",
      "#9900B3",
      "#E64D66",
      "#4DB380",
      "#FF4D4D",
      "#99E6E6",
      "#6666FF",
      "#86FC3E",
      "#02876D",
      "#DFAFFB",
      "#A097AF",
      "#E703F8",
      "#661193",
      "#93E25E",
      "#0F938F",
      "#9C2B0A",
      "#F7FCDB",
      "#787DB6",
      "#DE33D2",
      "#485725",
      "#4EC329",
      "#D5107A",
      "#7B8A54",
      "#8DDFCD",
      "#6771FA",
      "#38D2C7",
      "#190684",
      "#F46736",
      "#1BD675",
      "#FB7240",
      "#F86EC9",
      "#64C3CD",
      "#C6E373",
      "#6E17A1",
      "#116EDC",
      "#B8C832",
      "#80F02D",
      "#FED3BC",
      "#A25081",
      "#B26A88",
      "#5358FD",
      "#59D10F",
      "#63B844",
      "#B4C05A",
      "#CA881A",
      "#29A4A7",
      "#B7A29D",
      "#490EE9",
      "#81BAEA",
      "#8A26E5",
      "#815B00",
      "#A2A290",
      "#1FF429",
      "#60CC4E",
      "#7274CB",
      "#693891",
      "#5B663E",
      "#0C6D34",
      "#2C05C9",
      "#E158D7",
      "#9914E7",
      "#92C795",
      "#BF154B",
      "#6FC597",
      "#C811B8",
      "#2DF9C6",
      "#8EF83C",
      "#715AFD",
      "#1E80E5",
      "#35C85D",
      "#9D86DF",
      "#2304F7",
      "#3F07AB",
      "#B689FA",
      "#7CE4B0",
      "#1A8009",
      "#FC815A",
      "#A003C7",
      "#D974F9",
      "#E778C6",
      "#BAD1B8",
      "#1B22E7",
      "#026AB3",
      "#5DDFEE",
      "#0A9DA3",
      "#0587CC",
      "#10508E"
    ]
  }),
  methods: {
    getColorArray() {
      return this.colorArray;
    },
    async fetchBaselineStationsForStation(regionId, stationId) {
      // console.log(`fetch Baseline stations ${regionId}`);
      try {
        const response = await axios.get(
          `/baseline/region/${regionId}/baseline-stations?virtual_station_id=${stationId}`
        );
        return response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchLayers(regionId) {
      // console.log("fetch layers");
      const client = this.$appConfig.keycloakClient;
      const response = await this.$rastless.get(
        `/layers?client=${client}&region_id=${regionId}&token=${this.accessToken}`
      );
      if (response.data.length > 0) {
        const data = response.data.sort((a, b) =>
          a.product.localeCompare(b.product)
        );
        let filterdLayer = null;
        if (!this.isAdmin) {
          filterdLayer = data.filter(
            layer => layer.product !== "rgb" && layer.product !== "qut"
          );
        } else {
          filterdLayer = data.filter(layer => layer.product !== "rgb");
        }

        return filterdLayer;
      }
    },
    async fetchVirtualStation(vsStationId) {
      const response = await axios.get(
        `userdata/virtual-stations/${vsStationId}`
      );
      return response.data;
    },
    async fetchSingleStationStats(regionId, layerId, stationId) {
      try {
        const response = await axios.get(
          `/baseline/region/${regionId}/baseline-stations/?rastless_layer_id=${layerId}&virtual_station_id=${stationId}&stats`
        );
        return response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async fetchBaselineStationsForLayer(regionId, layerId, stats = false) {
      // console.log(`fetch Baseline information for layer ${layerId}`);
      try {
        if (stats) {
          const response = await axios.get(
            `/baseline/region/${regionId}/baseline-stations/?rastless_layer_id=${layerId}&stats`
          );
          return response.data;
        } else {
          const response = await axios.get(
            `/baseline/region/${regionId}/baseline-stations/?rastless_layer_id=${layerId}`
          );
          return response.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchStatistics(regionId, stationId, resolution) {
      // console.log("fetchStatisticsWithinTimeRange");
      const response = await axios.get(
        `/baseline/region/${regionId}/baseline-stations/${stationId}/statistics/?temporal_resolution=${resolution}`
      );
      return response.data.data;
    },
    async fetchAllStatistics(regionId, stationId) {
      const response = await axios.get(
        `/baseline/region/${regionId}/baseline-stations/${stationId}/statistics/?temporal_resolution=all`
      );
      let data = [];
      let datetime = [];
      response.data.forEach(point => {
        datetime.push(point[0]);
        data.push(point[1]);
      });
      const dataObject = { data: data, datetime: datetime };
      return dataObject;
    },
    async fetchTimesteps(product, token) {
      const response = await this.$rastless.get(
        `/layers/${product.rastless_layer_id}/steps?details=false&token=${token}`
      );
      return response.data;
    },
    async fetchStatisticsWithinTimeRange(
      regionId,
      stationId,
      resolution = undefined,
      startDate,
      endDate
    ) {
      // console.log(`fetchStatisticsWithinTimeRange ${resolution}`);
      const response = await axios.get(
        `/baseline/region/${regionId}/baseline-stations/${stationId}/statistics/?temporal_resolution=${resolution}&start=${startDate}&end=${endDate}`
      );
      return response.data.data;
    },
    async fetchStatisticsMeanWithinTimeRange(
      regionId,
      stationId,
      resolution,
      calc,
      startDate,
      endDate
    ) {
      const response = await axios.get(
        `/baseline/region/${regionId}/baseline-stations/${stationId}/statistics/?calc=${calc}&temporal_resolution=${resolution}&start=${startDate}&end=${endDate}`
      );
      return response.data;
    },
    async fetchStationAlerts(stationId) {
      const response = await axios.get(`/alerts/virtual-station/${stationId}/`);
      return response.data;
    },
    async fetchRegionThresholds(region) {
      const response = await axios.get(
        `/alerts/region/${region}/global-thresholds/`
      );
      return response.data;
    },
    getStatusColor(value, threshold) {
      if (threshold) {
        if (value > threshold.threshold_alert) {
          return "#f24726";
        } else if (
          threshold.threshold_warning &&
          threshold.threshold_warning <= value &&
          value <= threshold.threshold_alert
        ) {
          return "#F6A124";
        } else {
          return "#8fd14f";
        }
      } else {
        return "#000000";
      }
    },
    getPLotConfigs() {
      let plotlyFormat = {
        modeBarButtonsToRemove: [
          "toggleHover",
          "toggleSpikelines",
          "resetScale2d",
          "lasso2d",
          "pan2d",
          "zoom2d",
          "select2d"
        ],
        plotlyServerURL: "https://chart-studio.plotly.com",
        showSendToCloud: true,
        responsive: true
      };
      return plotlyFormat;
    },
    getBarLayout(unit) {
      return {
        margin: { autoexpand: false, l: 60, r: 120, b: 22, t: 15, pad: 0 },
        title: false,
        barmode: "group",
        bargroupgap: 0.2,
        yaxis: {
          title: {
            text: unit
          }
        }
      };
    },
    getBarLayoutCompare(unit) {
      return {
        margin: { autoexpand: true, l: 60, r: 180, b: 22, t: 15, pad: 0 },
        title: false,
        barmode: "group",
        bargroupgap: 0.2,
        yaxis: {
          title: {
            text: unit
          }
        }
      };
    },
    getBoxGroupLayout(unit) {
      return {
        margin: { autoexpand: true, l: 60, r: 120, b: 22, t: 15, pad: 0 },
        title: false,
        boxmode: "group",
        yaxis: {
          title: {
            text: unit
          }
        }
        // xaxis: {
        //   autorange: false,
        // }
      };
    },
    getCompareBoxGroupLayout(unit) {
      return {
        margin: {
          autoexpand: true,
          l: 60,
          r: 14,
          b: 22,
          t: 15,
          pad: 0
        },
        title: false,
        autotypenumbers: "strict",
        categoryorder: "array",
        boxmode: "group",
        yaxis: {
          title: {
            text: unit
          }
        }
        // xaxis: {
        //   autorange: false,
        // }
      };
    },
    getBoxLayout(unit) {
      return {
        margin: { autoexpand: false, l: 60, r: 14, b: 22, t: 15, pad: 0 },
        showlegend: false,
        autotypenumbers: "strict",
        categoryorder: "array",
        yaxis: {
          title: {
            text: unit
          },
          zeroline: true
        }
      };
    },
    getLayout(unit) {
      return {
        margin: { autoexpand: false, l: 60, r: 20, b: 30, t: 15, pad: 0 },
        title: false,
        hovermode: "closest",
        yaxis: {
          title: {
            text: unit
          }
        }
      };
    },
    addThresholdLayout(layout, alert, warning) {
      layout.shapes = [];
      if (warning !== null) {
        layout.shapes.push({
          type: "line",
          xref: "paper",
          x0: 0,
          y0: warning,
          x1: 1,
          y1: warning,
          line: {
            color: "rgb(255, 195,77)",
            width: 2
          }
          // label: {
          //   text: "Warning"
          // }
        });
      }
      if (alert != null) {
        layout.shapes.push({
          type: "line",
          xref: "paper",
          x0: 0,
          y0: alert,
          x1: 1,
          y1: alert,
          line: {
            color: "rgb(255, 0, 0)",
            width: 2
          }
        });
      }
      return layout;
    }
  },
  computed: {
    ...mapState("management", ["accessToken"]),
    ...mapGetters("management", ["activeRegion"]),
    isAdmin() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return roles && roles.includes("admin");
    }
  }
};

export default baselineMixin;
