import { mapActions, mapState } from "vuex";

let RegionMixin = {
  methods: {
    ...mapActions("management", ["getOrFetchRegions", "setActiveRegion"]),
    ...mapActions("dashboard", [
      "setAllData",
      "setSelectedStartDate",
      "setSelectedEndDate"
    ]),

    getActiveRegion(regions, regionId) {
      return regions.features.find(x => x.id === parseInt(regionId));
    },
    getRegionName(item) {
      let regionName = item.properties.name;
      if (item.properties?.country_code) {
        regionName = `${regionName}, ${item.properties.country_code}`;
      }
      return regionName;
    },
    changeRegionRoute(item) {
      this.setAllData(false);
      this.setSelectedStartDate(null);
      this.setSelectedEndDate(null);
      this.$router.push({
        name: this.$route.name,
        params: { regionId: item.id }
      });
    }
  },
  computed: {
    ...mapState("management", ["regions"]),
    activeRegion() {
      return this.getActiveRegion(this.regions, this.$route.params.regionId);
    }
  }
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.getOrFetchRegions().then(regions => {
  //       if (to.params?.regionId) {
  //         const activeRegion = vm.getActiveRegion(regions, to.params.regionId);
  //         if (!activeRegion) {
  //           vm.$router.push({
  //             name: "Regions"
  //           });
  //         } else {
  //           vm.setActiveRegion(activeRegion);
  //         }
  //       }
  //     });
  //   });
  // }
};

export default RegionMixin;
