import axios from "axios";

let dataManagementMixin = {
  methods: {
    async markTimestepsAsDeleted(
      regionId,
      layerId,
      virtualStationId,
      timeStepArray
    ) {
      const response = await axios.post(
        `/userdata/raster-data/region/${regionId}/layer/${layerId}`,
        {
          rastless_layer_id: layerId,
          timesteps: timeStepArray,
          virtual_station: virtualStationId
        }
      );
      return response.data;
    },
    async getMarkedTimesteps(regionId, layerId, virtualStationId) {
      const response = await axios.get(
        `/userdata/raster-data/region/${regionId}/layer/${layerId}/virtual_station/${virtualStationId}`
      );
      return response.data;
    },
    async resetMarkedTimesteps(layerId, virtualStationId) {
      const response = await axios.post(`/userdata/raster-data/reset`, {
        rastless_layer_id: layerId,
        virtual_station: virtualStationId
      });
      return response.data;
    },
    async filterOutMarkedSteps(timeseries, regionId, layerId, stationId) {
      const markedSteps = await this.getMarkedTimesteps(
        regionId,
        layerId,
        stationId
      );
      markedSteps.forEach(step => {
        const index = timeseries.datetime.indexOf(step);
        timeseries.datetime.splice(index, 1);
        if (timeseries.data?.B1) {
          timeseries.data.B1.splice(index, 1);
        } else {
          timeseries.data.splice(index, 1);
        }
      });
      return timeseries;
    },
    async filterOutMarkedStepsForAlerts(
      timeseries,
      regionId,
      layerId,
      stationId
    ) {
      const markedSteps = await this.getMarkedTimesteps(
        regionId,
        layerId,
        stationId
      );
      let markedIndexes = [];
      markedSteps.forEach(step => {
        timeseries.statistics.forEach((point, index) => {
          if (point.scene_date == step) {
            markedIndexes.push(index);
          }
        });
      });
      // need to sort in from high to low, to get the right position in array while splicing
      markedIndexes = markedIndexes.sort(function(a, b) {
        return b - a;
      });
      markedIndexes.forEach(index => {
        timeseries.statistics.splice(index, 1);
      });
      return timeseries;
    }
  }
};

export default dataManagementMixin;
