<template>
  <div>
    <v-menu
      v-model="filterDialog"
      top
      left
      :close-on-content-click="false"
      :nudge-width="400"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="pr-0" color="primary" dark text v-bind="attrs" v-on="on">
          <v-icon small>mdi-filter</v-icon>
          {{ $t("baseline.filterPeriodYears") }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          {{ $t("baseline.selectTimeFrame") }}
        </v-card-title>
        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
          <v-tab key="years">
            {{ $t("baseline.selectYears") }}
          </v-tab>
          <v-tab key="period">
            {{ $t("baseline.fromTo") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="year">
            <v-card color="basil" flat>
              <v-card-text
                class="pt-1"
                style="max-height:450px; overflow-y:auto; overflow-x: hidden "
              >
                <v-checkbox
                  :label="$t('baseline.selectAll')"
                  @click="toggleAllYears"
                  v-model="activateAll"
                  hide-details
                ></v-checkbox>
                <v-divider />
                <v-row no-gutters v-for="year in years" :key="year">
                  <v-checkbox
                    :value="year"
                    :label="year"
                    v-model="selectedYears"
                    hide-details
                  ></v-checkbox>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="period">
            <v-card color="basil" flat>
              <v-card-text class="pt-1">
                <v-row class="mt-5">
                  <v-col>
                    <v-date-picker
                      v-model="dateRange"
                      range
                      no-title
                    ></v-date-picker>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col class="mx-8">
                    <v-row class="mt-5">
                      <v-text-field
                        v-model="dateRange[0]"
                        :label="$t('baseline.startDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        disabled
                      ></v-text-field>
                    </v-row>
                    <v-row class="justify-center mt-5 black--text" cols="auto">
                      {{ $t("baseline.to") }}
                    </v-row>
                    <v-row class="mt-5">
                      <v-text-field
                        v-model="dateRange[1]"
                        :label="$t('baseline.endDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        disabled
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "PeriodFilter",
  props: {
    years: Array
  },
  data: function() {
    return {
      selectedYears: [],
      activateAll: true,
      filterDialog: false,
      tab: null,
      startDate: null,
      endDate: null,
      dateRange: []
    };
  },
  methods: {
    toggleAllYears() {
      if (this.activateAll) {
        this.selectedYears = this.years;
      } else {
        this.selectedYears = [];
      }
    }
  },
  watch: {
    selectedYears: {
      handler(value) {
        this.$emit("filteredYears", value);
      }
    },
    dateRange: {
      handler(value) {
        if (value.length === 2) {
          this.$emit("filteredPeriod", value);
        }
      }
    },
    years: {
      handler() {
        this.toggleAllYears();
      }
    }
  },
  beforeMount() {
    this.selectedYears = this.years;
  }
};
</script>

<style scoped></style>
